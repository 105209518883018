/* ================================================
   Project: MEP Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.custom-pagination {
    gap: 6px !important;
}

.custom-pagination li {
    height: 38px;
    width: 38px;
    background-color: var(--muted-twentyfive);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: var(--black-one);
    font-size: var(--fs-15);
    cursor: pointer !important;
}

.custom-pagination li.active {
    background-color: var(--purple);
    color: var(--white);
    cursor: pointer !important;
}

.custom-pagination li.active:hover {
    background-color: var(--purple);
    color: var(--white);
}

.custom-pagination li:hover {
    background-color: var(--purple-two);
    color: var(--white);
}

.custom-pagination li:hover svg path{
    stroke: var(--white) !important;
}

.custom-pagination li.disabled {
    background-color: var(--muted-twentysix);
    cursor: default !important;
}

.custom-pagination li.disabled:hover svg path{
    stroke: var(--muted) !important;
}

.custom-pagination li.disabled svg {
    color: var(--muted);
}

.custom-pagination li.disabled:hover {
    background-color: var(--muted-twentysix);
}

.custom-pagination li.disabled:hover svg {
    color: var(--muted);
}

.custom-pagination li.page-item {
    cursor: default !important;
}