/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.

   Note: Not Change Any File Postion in this File.
=================================================== */

/* <-----  Layout Stylesheet File Atteched Bellow -----> */
@import url('./layout/index.css');


/* <-----  Pages Stylesheet File Atteched Bellow -----> */
@import url('./pages/index.css');


.img-import {
   height: 72px;
   width: 72px;
   border-radius: 50%;
   background-color: #EAEAEA;
   border: 1px dashed #9F2F48;
   margin: 0 auto;
}

.import-text{
   color:#9F2F48 ;
   font-size: var(--fs-12);
}