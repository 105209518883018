/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.

    Note: Not Change Any CSS Postion in this File.
=================================================== */

.table-title {
    font-size: var(--fs-18) !important;
    line-height: 22px !important;
    color: var(--black-one) !important;
}

.common-table {
    margin-bottom: 0px !important;
}

.common-table thead tr {
    border-top: 1px solid var(--muted-six) !important;
    border-bottom: 1px solid var(--muted-six) !important;
}

.common-table thead tr th {
    padding: 19px 26px 19px 20px !important;
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-13) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
}

.common-table thead tr th.sorting {
    position: relative !important;
    cursor: pointer !important;
}

.common-table thead tr th.sorting::before {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE1TDEyIDlMNiAxNSIgc3Ryb2tlPSIjMTQxQjM0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjE2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    position: absolute;
    width: 10px;
    height: 10px;
    right: 10px !important;
    line-height: 20px !important;
    top: 14px;
    visibility: hidden;
    opacity: .6;
}

.common-table thead tr th.sorting::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTk5NzcgOS4wMDAwNUwxMS45OTk4IDE1TDE3Ljk5OTggOSIgc3Ryb2tlPSIjMTQxQjM0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjE2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    position: absolute;
    width: 10px;
    height: 10px;
    right: 10px !important;
    line-height: 20px !important;
    bottom: 18px;
    visibility: hidden;
    opacity: .6;
}

.common-table thead tr th.sorting:hover::before,
.common-table thead tr th.sorting:hover::after {
    visibility: visible;
}


.common-table thead tr th p {
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-13) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important
}


.common-table tbody tr {
    border-top: 1px solid var(--muted-six) !important;
    border-bottom: 1px solid var(--muted-six) !important;
}


.common-table tbody tr td {
    padding: 13px 20px !important;
    color: var(--muted) !important;
    font-weight: var(--font-normal);
    font-size: var(--fs-15) !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
}

.common-table tbody tr td .profile-icon {
    height: 34px;
    width: 34px;
    overflow: hidden;
    border-radius: 50%;
    text-transform: uppercase;
    font-size: var(--fs-13);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    cursor: pointer;
}

.common-table tbody tr:nth-child(6n-5) td .profile-icon {
    background-color: var(--lighter-shade);
    color: var(--purple);
}

.common-table tbody tr:nth-child(6n-4) td .profile-icon {
    background-color: var(--pink);
    color: var(--red);
}

.common-table tbody tr:nth-child(6n-3) td .profile-icon {
    background-color: var(--green-one);
    color: var(--green);
}

.common-table tbody tr:nth-child(6n-2) td .profile-icon {
    background-color: var(--orange-one);
    color: var(--orange);
}

.common-table tbody tr:nth-child(6n-1) td .profile-icon {
    background-color: var(--cyan);
    color: var(--dark-skyblue);
}

.common-table tbody tr:nth-child(6n-0) td .profile-icon {
    background-color: var(--muted-twentytwo);
    color: var(--muted-twentythree);
}

.common-table tbody tr td a {
    text-decoration: none !important;
    line-height: 17px;
}

.common-table tbody tr td a p {
    font-size: var(--fs-15);
}

.common-table tbody tr td a p,
.common-table tbody tr td p {
    color: var(--black-one);
    line-height: 17px;
}

.common-table tbody tr td a:hover p,
.common-table tbody tr td a:hover {
    color: var(--purple-five) !important;
}

.common-table tbody tr td small {
    color: var(--muted) !important;
    font-size: var(--fs-13) !important;
}

.common-table tfoot tr {
    border-top: 1px solid var(--muted-six) !important;
    border-bottom: 1px solid var(--muted-six) !important;
}

.common-table tfoot tr td {
    padding: 19px 26px 19px 20px !important;
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-13) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
}

.common-table tfoot tr td p {
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-13) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important
}

.common-table .blank-table-view {
    padding: 100px 20px;
    max-width: 450px;
    margin: 0 auto;
}

.common-table .blank-table-view h5 {
    font-size: var(--fs-18) !important;
    line-height: 22px !important;
    color: var(--black-one) !important;
}

.common-table .blank-table-view p {
    font-size: var(--fs-15) !important;
    color: var(--muted) !important;
    line-height: 20px !important;
    white-space: wrap;

}

.custom-table-infotext {
    color: var(--muted-four);
}

.tbl-content thead tr th {
    background-color: #F1F1F1 !important;
}