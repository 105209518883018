/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */
.forgot-password-content {
    min-height: 100vh;
}

.forgot-password-content .forgot-password-sub-conetnt {
    max-width: 460px;
}

.forgot-password-content .forgot-password-sub-conetnt::before {
    width: 238px;
    height: 233px;
    content: " ";
    z-index: -1;
    position: absolute;
    top: -35px;
    left: -45px;
    /* background-image: url('/public/assets/images/login/shapes/shape-top.svg'); */
}

.forgot-password-content .forgot-password-sub-conetnt::after {
    width: 180px;
    height: 180px;
    content: " ";
    position: absolute;
    z-index: -1;
    bottom: -30px;
    right: -56px;

}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .welcome-content h4 {
    font-size: var(--fs-24);
    color: var(--black-one);
    line-height: 38px;
}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .welcome-content p {
    font-size: var(--fs-15);
    line-height: 21px;
    color: var(--muted);
}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .backtologin-link {
    margin-bottom: 32px;
}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .backtologin-link a {
    font-size: var(--fs-15);
    color: var(--purple);
}