.card-content {
  height: 100%;
  gap: 10px; /* Adjust spacing between text and image */
}

.text-container {
  text-align: left;
}

.image-container img {
  max-width: 50px;
  height: auto;
}
.card-outer-wrapper {
  border: none !important;
  width: 267px;
  height: 92px !important;
}

.clear-btn-link,
.clear-btn-link:active,
.clear-btn-link:hover,
.clear-btn-link:focus-visible,
.clear-btn-link:focus-within,
.clear-btn-link:focus {
  color: #181818 !important;
  font-size: 15px !important;
}

.custom-filter-btn {
  padding: 7px 16px;
  background-color: transparent;
  border: 1px solid #ddd;
}

.btn-active {
  border: 1px solid #9f2f48 !important;
  background: rgba(159, 47, 72, 0.12) !important;
}

.btn-active::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #9f2f48;
  top: -5px;
  right: -5px;
}

.card-border-active {
  border: 1px solid #9f2f48 !important;
}

.card-numbers-color {
  color: #5e5873;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
}
.card-para-color {
  color: #6e6b7b;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

/* NPA Properties list section */
.npa-list-section {
  margin-top: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.search-form .search-input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: 250px;
}

.action-buttons button {
  font-size: 14px;
}

.common-table {
  background-color: #fff;
}

.common-table thead tr th {
  padding: 9px 26px 9px 20px !important;
}

.common-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  text-align: center;
}

.common-table td {
  text-align: center;
  vertical-align: middle;
}

.bank-logo-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.arrow-up-down {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-bottom: -21px;
  margin-right: -20px;
}
.checvron-down-img {
  margin-top: -3px;
}
.user-initials {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3545;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
}

.pagination {
  margin-bottom: 0;
}

.pagination .page-item.active .page-link {
  background-color: #dc3545;
  border-color: #dc3545;
}

.search-action-bar {
  background-color: #fff; /* White background */
}

.search-input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: 250px;
}
.basic-style {
  background: transparent;
  color: #5e5873 !important;
}
.mr-17 {
  margin-right: 17px;
}
.ml-17 {
  margin-right: 5px;
}
.mr-27 {
  margin-right: 45px;
}
.mt-21{
  margin-top:21px;
}
.contact-detail-img {
  height: 22px;
  width: 25px;
  margin-right: 10px;
}
.contact-detail-head {
  color: #5e5873;
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
}
.cursor-pointer {
  cursor: pointer;
}

.npa-details {
  color: #333333 !important;
  font-size: 18px !important;
}

.p-light {
  color: #53587a;
  font-weight: 400;
  font-size: 13px;
}

.p-extra-light {
  color: #8f8f8f !important;
  font-size: 13px;
  font-weight: 400;
}

.p-dark {
  color: #333333 !important;
  font-size: 13px;
  font-weight: 400;
}

.address-mb {
  margin-bottom: -15px !important;
}
