/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.common-form-heading-label {
    font-size: var(--fs-18);
    color: #444050;
    letter-spacing: inherit;
    text-transform: inherit;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
}
