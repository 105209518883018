/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.

   Note: Not Change Any Css Postion in this File.
=================================================== */

.common-btn {
    padding: 7.5px 20px !important;
    font-size: var(--fs-15) !important;
}

/* Primary Button Style Start */
.btn-primary {
    background-color: var(--primary-color) !important;
    color: var(--white) !important;
}

.btn-primary:hover {
    background-color: var(--darker-shade) !important;
}

.primary-shadow {
    box-shadow: 0 0.125rem 0.375rem 0 var(--lighter-shade);
}

.btn-outline-primary {
    border: 1px solid var(--purple) !important;
    color: var(--purple) !important;
}
.btn-outline-primary:hover{
    background-color:transparent !important;
}

/* Primary Btton Lighter Shade */
.btn-primary-light{
    background-color: var(--lighter-shade) !important;
    color: var(--primary-color) !important;
}


/* Muted Button Style Start */
.btn-muted {
    background-color: var(--muted-seven) !important;
    color: var(--muted-one) !important;
}

.btn-muted:hover {
    background-color: var(--muted-twentynine) !important;
}

/* Danger-outline Button Style Start */

.btn-danger{
    background-color: var(--red) !important;
    color: var(--white) !important;
}
.btn-outline-danger {
    border: 1px solid var(--red) !important;
    color: var(--red) !important;
}

.w-30{
    width: 30% !important;
}

.mr-10{
    margin-right: 20px;
}