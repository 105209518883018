.export-icon{
    margin-right:10px;
}

.red-chevron{
    margin-left: 7px;
}

.export-btn-icon{
    border: none;
    padding: 7px 15px 9px 15px;
    border-radius: 0.375rem;
    font-weight: 500;
}

.p-light {
    color: #53587a;
    font-weight: 400;
    font-size: 18px;
  }
  
  .p-extra-light {
    color: #8f8f8f !important;
    font-size: 13px;
    font-weight: 400;
  }
  
  .p-dark{
    color:#333333 !important;
    font-size: 13px;
    font-weight: 400;
  }

  .user-cell {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .user-initials {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dc3545;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
  }

  .modal-p{
    padding: 10px 5px 5px 10px;
  }