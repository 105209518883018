/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
   
   Note: Not Change Any File Postion in this File.
=================================================== */

.common-card .card-header h5 {
    font-size: var(--fs-18) !important;
    color: var(--black-three) !important;
    line-height: 28px !important;
}

.common-card .common-card-header a {
    color: var(--purple) !important;
    line-height: 22px !important;
    font-size: var(--fs-15) !important;
}

.common-card .common-card-header a:hover {
    color: var(--purple-six) !important;
}

.common-card .common-card-header p {
    font-size: var(--fs-14);
    color: var(--muted);
}

.common-card .common-card-header p span {
    color: var(--black-one) !important;
}

.common-card .common-card-body .card-profile .user-icon {
    height: 40px;
    width: 40px;
    /* background-color: var(--purple); */
    font-size: var(--fs-13);
    color: var(--white);
    letter-spacing: 1px;
}

.common-card .common-card-body h6 {
    color: var(--black-one);
    line-height: 22px;
    font-size: var(--fs-15);
}


.common-card .common-card-body .card-content p {
    line-height: 20px;
    font-size: var(--fs-16);
    color: var(--muted);
}

.common-card .common-card-body .status-content {
    gap: 12px;
}

.common-card .common-card-body .status-content .status-icon {
    height: 40px;
    width: 40px;
}

.common-card .common-card-body .status-content span {
    font-size: var(--fs-13) !important;
    color: var(--muted) !important;
    line-height: 18px;
}

.common-card .common-card-body .status-content.success-status .status-icon {
    background-color: var(--green-one) !important;
    color: var(--green) !important;
}

.common-card .common-card-body .status-content.success-status h6 {
    color: var(--green) !important;
}

.common-card .common-card-body .status-content.success-status .status-icon svg {
    color: var(--green) !important;
}


.common-card .common-card-body .status-content.danger-status .status-icon {
    background-color: var(--pink) !important;
    color: var(--red) !important;
}

.common-card .common-card-body .status-content.danger-status h6 {
    color: var(--red) !important;
}

.common-card .common-card-body .status-content.danger-status .status-icon svg {
    color: var(--red) !important;
}

/* danger-status */

.common-card .common-card-body .status-content.primary-status .status-icon {
    background-color: var(--purple-four) !important;
    color: var(--purple) !important;
}

.common-card .common-card-body .status-content.primary-status .status-icon svg {
    color: var(--purple) !important;
}