/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

:root {
    --white: #ffffff;
    --white-one: #f8f7fa;
    --white-two: #ffffff1c;
    --white-three: #ffffff00;
    --white-four: #ffffffe0;
    --white-five: #22292F;
    --black: #000000;
    --black-one: #53587A;
    --black-two: #f0f0f1;
    --black-three:#333333;
    --dark-skyblue: #00bad1;
    --muted: #53587A;
    --muted-one: #808390;
    --muted-two: #8d8a94;
    --muted-three: #97959e;
    --muted-four: #acaab1;
    --muted-five: #b4b3b9;
    --muted-six: #e6e6e8;
    --muted-seven: #ebebed;
    --muted-eight: #eeedf0;
    --muted-nine: #eeeeee;
    --muted-ten: #eeeeef;
    --muted-eleven: #2F2B3D0F;
    --muted-twelve: #2f2b291f;
    --muted-thirdteen: #f8f7fab3;
    --muted-fourteen: #f8f7fa6e;
    --muted-fifthteen: #f8f7fa00;
    --muted-sixteen: #2f2b3d1f;
    --muted-seventeen: #f3f2f3;
    --muted-eighteen: #2f2b3d29;
    --muted-nineteen: #2f2b3d24;
    --muted-twenty: #82808b;
    --muted-twentyone: #D8D6DE;
    --muted-twentytwo: #e2e2e2;
    --muted-twentythree: #4b4b4b;
    --muted-twentyfour: #f3f3f4;
    --muted-twentyfive: #2f2b3d14;
    --muted-twentysix: #EFEEF0;
    --muted-twentyseven: #eff2f7;
    --muted-twentyeight: #2f2b3d1a;
    --muted-twentynine: #e1e1e4;
    --cyan: #d6f4f8;
    --purple: #9F2F48; /* Base */
    --purple-one: #7C2439; /* Darker variant */
    --purple-two: #B9475E; /* Slightly lighter */
    --purple-three: #D16C82; /* Mid-tone */
    --purple-four: #F3E6E9; /* Lighter variant */
    --purple-five: #FCC7CF; /* Much lighter */
    --purple-six: #FDE5E9; /* Near pastel */
    --yellow:#DF8A00;
    --yellow-one: #ebd0a6;
    --orange: #ff9f43;
    --orange-one: #fff0e1;
    --pink: #ffe2e3;
    --red: #ff4c51;
    --red-one: #e64449;
    --red-two: #fcf1ef;
    --green: #28c76f;
    --green-one: #ddf6e8;
    --link-green: #00796B;
    --blue: #edf7fe;
    --primary-color: #9F2F48; /* Base color */
    --lighter-shade: #F3E6E9; /* Lighter variant of the primary color */
    --darker-shade: #7C2439; /* Darker variant for the shadow */
}
