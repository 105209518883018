/* ================================================
   Project: My Estate Point
   Author: DCPL Technology Private Limited.
=================================================== */

.custom-navigationbar {
    border-radius: 6px;
    z-index: 1000;
    box-shadow: 0 .125rem .5rem 0 var(--muted-sixteen);
    background-color: var(--white-four) !important;
    top: 16px;
}

.custom-navigationbar .pg-title {
    color: var(--black-one);
    font-size: 22px !important;
}

.custom-navigationbar .profile-menu .profile-submen {
    box-shadow: 0 .25rem 1.125rem 0 var(--muted-eighteen);
}

.user-profile-icon {
    height: 40px;
    width: 40px;
    background: var(--primary-color);
    color: white;
    font-size: small;
}

.custom-navigationbar .profile-menu .profile-toggle,
.custom-navigationbar .profile-menu .profile-toggle:hover {
    --bs-link-color: transparent !important;
    --bs-link-hover-color: transparent !important;
}

.custom-navigationbar .profile-menu .profile-toggle,
.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user {
    height: 40px;
    width: 40px;

}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item {
    line-height: 20px !important;
}

.custom-navigationbar .profile-menu .profile-toggle::before,
.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    box-shadow: 0 0 0 2px var(--white);
    background-color: var(--green);

}

.custom-navigationbar .profile-menu .profile-toggle::after {
    display: none !important;
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item:hover {
    border-radius: 6px;
    background-color: var(--muted-seventeen);
    color: var(--black-one);
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item h6 {
    font-size: var(--fs-15);
    color: var(--black-one);
    line-height: 22px;
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .post-tag {
    font-size: var(--fs-13);
    color: var(--muted-four);
    line-height: 20px;
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item span {
    font-size: var(--fs-15);
    color: var(--black-one);
}

.custom-navigationbar .profile-menu .profile-submenu .logout-btn:hover {
    background-color: transparent !important;
}

.custom-navigationbar .profile-menu .profile-submenu .logout-btn button {
    padding: 5px 14px;
    background-color: var(--red);
    border-color: var(--red);
    border-radius: 6px;
    font-size: var(--fs-13);
}

.custom-navigationbar .profile-menu .profile-submenu .logout-btn button:hover {
    background-color: var(--red-one);
    border-color: var(--red-one);
}

.custom-navigationbar .profile-menu .profile-submenu .profile-submenu-divider {
    border-top: 1px solid var(--muted-six);
    margin: 4px -8px;
}

.custom-navigationbar .profile-menu .profile-submenu {
    min-width: 224px;
}