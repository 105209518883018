.common-textarea {
    padding: 6px 14px !important;
    font-size: var(--fs-15);
    color: var(--black-one);
    background-clip: padding-box;
    border-color: var(--muted-twentyone) !important;
    line-height: 1.625;
    min-height: 40px;
    vertical-align: middle !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.common-textarea:hover {
    border-color: var(--muted-twenty) !important;
    outline-color: var(--muted-twenty) !important;
}

.common-textarea:focus {
    border-color: var(--purple) !important;
    box-shadow: 0 2px 6px 0 var(--purple-four) !important;
    color: var(--black-one);
    border-width: 2px;
    padding: calc(.426rem - 2px) calc(.9375rem - 2px);
    transition: all .15s;
}

.common-textarea::placeholder {
    font-size: var(--fs-15) !important;
    color:#B9B9C3 !important;
    font-weight: var(--font-normal) !important;
    transition: all .15s;
}

.common-textarea:focus::placeholder {
    transform: translateX(3px);
    transition: all .15s;
}

.common-textarea.error {
    border-width: 2px;
    border-color: var(--red) !important;
}
